import styled from 'styled-components'

const Input = styled.input`
  display: block;
  font-family: ${({ theme }) => theme.fontSans};
  font-size: 18px;
  line-height: 55px;
  text-transform: uppercase;
  border: 0;
  padding: 1px 30px 0;
  color: inherit;
  transition: border-color 0.2s;
  position: relative;
  width: 100%;
  outline: none;
  background: none;
  border-radius: 0;

  &:active {
    border-color: #fff;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    line-height: 55px;
    border: 1px solid transparent !important;
    background-color: ${({ theme }) => theme.black} !important;
    border: 0 !important;
    -webkit-text-fill-color: ${({ theme }) => theme.white};
    -webkit-box-shadow: 0 0 0px 1000px #000 inset !important;
  }
`

export default Input
