import React, { useState, useRef } from 'react'
import styled, { keyframes } from 'styled-components'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import H from '../styled/Heading'
import P from '../styled/Paragraph'
import I from '../styled/Input'

const move = keyframes`
  0% {
   transform: translateX(0px);
	 opacity: 1;
  }

	17% {
		opacity: 0.5;
	}
	34%{
    transform: translateX(10px);
	  opacity: 0;
		
	}
	50% {
    transform: translateX(-10px);
	  opacity: 0;
  }
  67% {
    opacity:0.5;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
`

const Wrapper = styled.div`
  width: 100%;
  max-width: 422px;
`

const Heading = styled(H)`
  margin-bottom: 30px;
`

const FormWrap = styled.div`
  position: relative;
`

const Form = styled.form``

const InputWrap = styled.div`
  position: relative;
`

const Border = styled.div`
  position: absolute;
  top: -1px;
  left: -1px;
  height: calc(100% + 2px);
  width: calc(100% + 2px);
  border: 1px solid
    ${({ theme, disabled }) => (disabled ? theme.whiteOpaque : theme.white)};
  transition: border-color 0.15s ease-in-out;
`

const Input = styled(I)``

const ButtonWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 72px;

  @media (hover: hover) {
    &:hover {
      button {
        animation: ${move} 0.6s ease-in-out;

        &:disabled {
          animation: none;
        }
      }
    }
  }
`
const Button = styled.button`
  display: block;
  width: 100%;
  height: 100%;
  background: url('../icons/arrow-white.svg') center no-repeat;
  background-size: 12px 12px;
  overflow: hidden;
  text-indent: -9999px;
  transition: opacity 0.1s;
  transform: translate3d(0, 0, 1px);

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`

const Consent = styled.div`
  margin-top: 17px;
`

const Checkbox = styled.input`
  display: none;

  &:checked + label:before {
    background: #fff;
  }
`

const Label = styled(P)`
  font-family: ${({ theme }) => theme.fontSansMedium};
  padding-left: 20px;
  position: relative;
  display: block;
  cursor: pointer;
  line-height: 16px;

  &:before {
    content: '';
    position: absolute;
    top: 2px;
    left: 0;
    display: block;
    width: 12px;
    height: 12px;
    border: 1px solid #fff;
    transition: background-color 0.2s;
  }
`

const Response = styled(P)`
  position: absolute;
  top: calc(100% + 16px);
  left: 0;
  width: 100%;

  a {
    text-decoration: underline;
  }
`

const Newsletter = () => {
  const [message, setMessage] = useState('')
  const [disabled, setDisabled] = useState(true)

  const submitButton = useRef(null)

  const submitForm = e => {
    e.preventDefault()

    const newsEmail = e.target.querySelector('input[type=email]').value

    if (newsEmail) {
      addToMailchimp(newsEmail)
        .then(data => {
          setMessage(data.msg)
          setTimeout(() => {
            setMessage('')
          }, 5000)
        })
        .catch(() => {
          setMessage('Undefined error')
          setTimeout(() => {
            setMessage('')
          }, 5000)
        })
    } else {
      setMessage('Enter a valid email address')
    }
  }

  return (
    <Wrapper>
      <Heading size={4}>Stay up to date</Heading>

      <FormWrap>
        <Form onSubmit={submitForm}>
          <InputWrap>
            <Border disabled={disabled} />

            <Input
              color="white"
              type="email"
              placeholder="email"
              name="EMAIL"
              id="mce-EMAIL"
              dis={disabled}
            />
            <ButtonWrapper>
              <Button disabled={disabled} as="button" ref={submitButton}>
                Subscribe
              </Button>
            </ButtonWrapper>
          </InputWrap>

          <Consent>
            <Checkbox
              onChange={() => setDisabled(!disabled)}
              type="checkbox"
              id="newsletter-consent"
              name="newsletter-consent"
              value="I agree to the privacy policy"
            />
            <Label as="label" size="xs" uppercase htmlFor="newsletter-consent">
              I agree to the privacy policy
            </Label>
          </Consent>
        </Form>

        <Response dangerouslySetInnerHTML={{ __html: message }} />
      </FormWrap>
    </Wrapper>
  )
}

export default Newsletter
